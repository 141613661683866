import React, { useState } from "react";
import { Box, Button, useTheme } from "@mui/material";
import { useGetCustomersQuery, useUpdateMeetingCredsMutation } from "state/api";
import Header from "components/Header";
import { DataGrid } from "@mui/x-data-grid";
import CredsModal from "components/CredsModal";

const Customers = () => {
  const theme = useTheme();
  const { data, isLoading } = useGetCustomersQuery();
  const [showCredsModal, setShowCredsModal] = useState(false)
  const [meetingDetails, setMeetingDetails] = useState({})
  const [updateMeetingCreds] = useUpdateMeetingCredsMutation();

  const renderMeetingInfoButton = (params) => {
    return (
      params.row.accountType === 'trainer' ?
        (

          <Button variant="contained"
            onClick={() => {
              setShowCredsModal(true);
              setMeetingDetails(params.row)
            }}
          >
            Meet Credentials
          </Button>
        ) : <></>
    )
  }
  function getModalStyle() {
    return {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      background: "#000000b5",
      width: "100%"
    };
  }
  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 0.5,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 0.5,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "country",
      headerName: "Country",
      flex: 0.4,
    },
    {
      field: "accountType",
      headerName: "Account Type",
      flex: 0.5,
    },
    {
      field: "meetingSetup",
      headerName: "Meeting Setup",
      flex: 0.6,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: renderMeetingInfoButton
    },
    {
      field: "isAccountComplete",
      headerName: "Account Complete",
      flex: 0.5,
    },
  ];
  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick")
      setShowCredsModal(false);
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    const formData = {
      meetUserName: event.target.meetUsername.value,
      meetPassword: event.target.meetPassword.value,
    }
    if (!formData.meetPassword || !formData.meetUserName) return;
    updateMeetingCreds({ id: meetingDetails._id, ...formData })

  }
  return (
    <Box m="1.5rem 2.5rem">
      <Header title="CUSTOMERS" subtitle="List of Customers" />
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <CredsModal
          open={showCredsModal}
          style={getModalStyle()}
          handleClose={handleClose}
          meetingDetails={meetingDetails}
          handleSubmit={handleSubmit}
        />
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={data || []}
          columns={columns}
        />
      </Box>
    </Box>
  );
};

export default Customers;
