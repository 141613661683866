import { S3Client } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";

export const handleUpload = async (imgFile) => {
  const {
    REACT_APP_AWS_REGION: region,
    REACT_APP_AWS_ACCESS_KEY_ID: accessKeyId,
    REACT_APP_AWS_SECRET_ACCESS_KEY: secretAccessKey,
    REACT_APP_AWS_BUCKET_NAME: bucketName,
    REACT_APP_AWS_THUMBNAILS: thumbnail_dir,
  } = process.env;

  const s3 = new S3Client({
    region,
    credentials: {
      accessKeyId,
      secretAccessKey,
    },
  });
  if (!imgFile) {
    alert("Please upload image file!!");
  }
  const params = {
    Bucket: bucketName,
    Key: `${thumbnail_dir}/thmb-${Date.now()}.png`,
    Body: imgFile,
  };

  const upload = new Upload({
    client: s3,
    params,
  });

  const { Location } = await upload.done();
  return Location;
};
export const formatDate = (dateInput) => {
  const date = new Date(dateInput);
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  const formattedDate = date.toLocaleDateString('en-US', options);
  return formattedDate
}