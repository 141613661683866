import { useState } from "react";

import {
  Box,
  useMediaQuery,
  FormControl,
  InputLabel,
  Input,
  Button,
  useTheme,
} from "@mui/material";
import Header from "components/Header";
import { useGetAllOffersQuery, usePostOfferMutation } from "state/api";
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { formatDate, handleUpload } from "helpers";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const CreateOffer = ({ data }) => {
  const [createOffer] = usePostOfferMutation();
  const [imgFile, setImgFile] = useState()
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission here
    // Add your API call or state update logic here
    const imageUrl = await handleUpload(imgFile);
    createOffer({
      name: e.target.elements.name.value,
      imgUrl: imageUrl,
      discountPercent: Number(e.target.elements.discount.value),
    })
    alert("Offer Created!!");
    document.getElementById("offerForm").reset();
    setImgFile();
  };


  return (
    <Box p={2}>
      <form onSubmit={handleSubmit} id="offerForm">
        <Box mb={2} width={500}>
          <FormControl fullWidth>
            <InputLabel>Offer name</InputLabel>
            <Input required name="name" />
          </FormControl>
        </Box>
        <Box mb={2} width={500}>
          <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
            Upload Image file
            <VisuallyHiddenInput type="file" accept=".png, .jpeg, .jpg" onChange={(e) => setImgFile(e.target.files[0])} />
          </Button>
          {imgFile && <span> &nbsp; {imgFile.name}</span>}
        </Box>
        <Box mb={2} width={500}>
          <FormControl fullWidth>
            <InputLabel>Discount %</InputLabel>
            <Input required type="number" name="discount" />
          </FormControl>

        </Box>
        {/* <Box mb={2} width={500}>
          <FormControl fullWidth>
            <InputLabel>Discount %  </InputLabel>
            <Input name="discountINR" required type="number" />
          </FormControl>
        </Box> */}
        <Button type="submit" variant="contained" color="primary">
          Create Offer
        </Button>

      </form>
    </Box>
  );
};

const Offer = () => {
  const theme = useTheme();
  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Offer Name",
      flex: 0.5,
    },
    {
      field: "discountPercent",
      headerName: "Discount % in ($ and ₹) ",
      flex: 0.5,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 0.5,
    },
  ];
  const { data, isLoading } = useGetAllOffersQuery();
  const isNonMobile = useMediaQuery("(min-width: 1000px)");

  return (
    <>
      <Box m="1.5rem 2.5rem">
        <Header title="Create Offers" subtitle="Create offers for courses." />
        {data || !isLoading ?
          <Box
            mt="20px"
            display="grid"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            justifyContent="space-between"
            rowGap="20px"
            columnGap="1.33%"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <CreateOffer data={data} />
          </Box> : (
            <>Loading...</>
          )
        }
      </Box>
      <Box
        m="40px"
        // mr="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >

        <Header title="List of Offers" />
        <DataGrid
          loading={isLoading}
          getRowId={(row) => row._id}
          rows={data ? data.map(d => {
            return {
              ...d,
              createdAt: formatDate(d.createdAt)
            }
          }) : []}
          columns={columns}
        />
      </Box>
    </>
  );
};

export default Offer;
