import React, { useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Button,
  Typography,
  Rating,
  useTheme,
  useMediaQuery,
  IconButton,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import Header from "components/Header";
import { useDeleteCourseMutation, useGetProductsQuery, useUpdateCourseMutation } from "state/api";
const Course = ({
  _id,
  title,
  description,
  price,
  overall_rating: rating,
  category,
  supply,
  stat,
  onDeleteCourse,
  onUpdateCourse,
  isHidden
}) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);
  const [hide, setHide] = useState(!!isHidden)
  const handleChange = (event) => {
    const status = event.target.checked
    setHide(status);
    onUpdateCourse({ id: _id, isHidden: status })
  };
  return (
    <Card
      sx={{
        backgroundImage: "none",
        backgroundColor: theme.palette.background.alt,
        borderRadius: "0.55rem",
      }}
    >
      <CardContent>
        <Typography
          sx={{ fontSize: 14 }}
          color={theme.palette.secondary[700]}
          gutterBottom
        >
          {category.replace("_", " ")}
        </Typography>
        <Typography variant="h5" component="div">
          {title}
        </Typography>
        <Typography sx={{ mb: "1.5rem" }} color={theme.palette.secondary[400]}>
          ${Number(price.amt).toFixed(2)}
        </Typography>
        <Rating value={rating} readOnly />

        <Typography variant="body2">{description?.short}</Typography>
      </CardContent>
      <CardActions>
        <Button
          variant="primary"
          size="small"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          See More
        </Button>
        <IconButton aria-label="delete" size="large" color="error" onClick={onDeleteCourse}>
          <DeleteIcon />
        </IconButton>

        {/* </Button> */}
      </CardActions>
      <FormGroup sx={{ ml: "1.5rem" }}>
        <FormControlLabel control={<Switch checked={hide} onChange={handleChange} />} label="Hide from Home" />
      </FormGroup>
      <Collapse
        in={isExpanded}
        timeout="auto"
        unmountOnExit
        sx={{
          color: theme.palette.neutral[300],
        }}
      >
        <CardContent>
          <Typography>id: {_id}</Typography>
          {/* <Typography>Supply Left: {supply}</Typography>
          <Typography>
            Yearly Sales This Year: {stat.yearlySalesTotal}
          </Typography>
          <Typography>
            Yearly Units Sold This Year: {stat.yearlyTotalSoldUnits}
          </Typography> */}
          <Typography>{description?.long}</Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
};

const Courses = () => {
  const { data, isLoading, refetch } = useGetProductsQuery();
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const [deleteCourse] = useDeleteCourseMutation();
  const [updateCourse] = useUpdateCourseMutation();

  const Title = () => <div>Courses <IconButton aria-label="refresh" size="large" onClick={refetch}>
    <RefreshIcon />
  </IconButton></div>
  return (
    <Box m="1.5rem 2.5rem">
      <Header title={<Title />} subtitle="See your list of courses." />
      {data || !isLoading ? (
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          justifyContent="space-between"
          rowGap="20px"
          columnGap="1.33%"
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
          {data.length > 0 && data.map(
            ({
              _id,
              title,
              description,
              price,
              overall_rating,
              category,
              supply,
              stat,
              isHidden
            }) => (
              <Course
                onDeleteCourse={() => deleteCourse({ id: _id })}
                onUpdateCourse={updateCourse}
                key={_id}
                _id={_id}
                title={title}
                description={description}
                price={price}
                overall_rating={overall_rating}
                category={category}
                supply={supply}
                stat={stat}
                isHidden={isHidden}
              />
            )
          )}
        </Box>
      ) : (
        <>Loading...</>
      )}
    </Box>
  );
};

export default Courses;
