import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom'


const initialState = {
  username: false,
  password: false,
}
export default function Login() {
  const theme = useTheme();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(initialState);
  const navigate = useNavigate()
  //check if token is expired
  const isTokenExpired = (token) => {
    if (!token) return true;
    const expiry = JSON.parse(atob(token.split(".")[1])).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  };

  // if token is expired, remove it from local storage
  // const TOKEN = JSON.parse(localStorage.getItem("token"));
  // if (TOKEN != null && !isTokenExpired(TOKEN)) {
  //   window.location.href = "/dashboard";
  // } else {
  //   setLoading(false);
  // }

  useEffect(() => {
    (async () => {
      const TOKEN = await JSON.parse(localStorage.getItem("token"));
      if (loading) {
        if (TOKEN != null && !isTokenExpired(TOKEN)) {
          navigate('/')
        } else {
          setLoading(false);
        }

      }
    })();
  }, [loading, navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(initialState)
    const data = new FormData(event.currentTarget);
    const credentials = {
      email: data.get("email"),
      password: data.get("password"),
    };
    if (!credentials.email) {
      return setError(prev => ({ ...prev, username: true }))
    }
    else if (!credentials.password) {
      return setError(prev => ({ ...prev, password: true }))
    }
    const response = await fetch(
      `${process.env.REACT_APP_BASE_DOMAIN}/auth/login?loginType=FOR_ADMIN`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(credentials),
      }
    );

    const user = await response.json();

    if (user.token) {
      localStorage.setItem("token", JSON.stringify(user.token));
      window.location.reload();
    } else {
      alert("Invalid credentials");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Studoi Admin
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>

        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          error={error.username}
          helperText={error.username ? "Email address is required" : ""}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          error={error.password}
          helperText={error.password ? "Password is required" : ""}

        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Sign In
        </Button>
      </Box>
    </Box>
  );
}
