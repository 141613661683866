import React from "react";

import {
  Box,
  useMediaQuery,
  FormControl,
  InputLabel,
  Input,
  Button,
  TextField,
  useTheme,
} from "@mui/material";
import Header from "components/Header";
import { useGetAllCouponsQuery, useGetProductsQuery, usePostCouponMutation } from "state/api";
import MultiChip from "components/MultiChip";
import { DataGrid } from '@mui/x-data-grid';

const CreateCoupon = ({ data }) => {
  const [createCouponCode] = usePostCouponMutation();
  const [course, setCourse] = React.useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    // Add your API call or state update logic here
    const courseIds = data.filter(c => course.includes(c.title)).map(c => c._id)
    createCouponCode({
      name: e.target.elements.name.value,
      description: e.target.elements.description.value,
      discount: e.target.elements.discount.value,
      discountINR: e.target.elements.discountINR.value,
      courseTags: courseIds
    })
    alert("Coupon Code Created!!");
    document.getElementById("couponForm").reset();
    setCourse([])
  };

  return (
    <Box p={2}>
      <form onSubmit={handleSubmit} id="couponForm">
        <Box mb={2} width={500}>
          <FormControl fullWidth>
            <InputLabel>Coupon Name</InputLabel>
            <Input required name="name" />
          </FormControl>
        </Box>
        <Box mb={2} width={500}>
          <TextField fullWidth
            label="Description"
            multiline
            rows={4}
            name="description"
            required
          />
        </Box>
        <Box mb={2} width={500}>
          <FormControl fullWidth>
            <InputLabel>Discount Amount in $</InputLabel>
            <Input required type="number" name="discount" />
          </FormControl>

        </Box>
        <Box mb={2} width={500}>
          <FormControl fullWidth>
            <InputLabel>Discount Amount in ₹  </InputLabel>
            <Input name="discountINR" required type="number" />
          </FormControl>
        </Box>
        <Box mb={2}>
          <MultiChip data={data} title="Courses" course={course} setCourse={setCourse} />
        </Box>
        <Button type="submit" variant="contained" color="primary">
          Create Code
        </Button>

      </form>
    </Box>
  );
};

const CouponCode = () => {
  const theme = useTheme();
  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Coupon Name",
      flex: 0.5,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 0.5,
    },
    {
      field: "discount",
      headerName: "Discount ($)",
      flex: 1,
    },
    {
      field: "discountINR",
      headerName: "Discount (₹)",
      flex: 0.4,
    },
  ];
  const { data, isLoading } = useGetProductsQuery();
  const { data: allCouponsData, isLoading: isCouponsLoading } = useGetAllCouponsQuery();
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  return (
    <>
      <Box m="1.5rem 2.5rem">
        <Header title="Coupon Code" subtitle="Create coupon code for courses." />
        {data || !isLoading ?
          <Box
            mt="20px"
            display="grid"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            justifyContent="space-between"
            rowGap="20px"
            columnGap="1.33%"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <CreateCoupon data={data} />
          </Box> : (
            <>Loading...</>
          )
        }
      </Box>
      <Box
        m="40px"
        // mr="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >

        <Header title="List of Coupons" />
        <DataGrid
          loading={isCouponsLoading || !allCouponsData}
          getRowId={(row) => row._id}
          rows={allCouponsData || []}
          columns={columns}
        />
      </Box>
    </>
  );
};

export default CouponCode;
