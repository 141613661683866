import { Button, FormControl, Input, InputLabel, Modal, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useGetMeetingCredsQuery } from 'state/api';

const CredsModal = ({ open, handleClose, style, handleSubmit, onClose, meetingDetails }) => {

  const { data, isLoading } = useGetMeetingCredsQuery(meetingDetails._id);

  const [toastMsg, setToastMsg] = useState(false);
  useEffect(() => {
    setToastMsg(false)
  }, [])

  const onSubmit = (e) => {
    setToastMsg(true)
    handleSubmit(e)
  }
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >

      {(!isLoading || data) ?
        <Box sx={{ ...style, width: "400px" }}>
          <form onSubmit={onSubmit}>
            <Box mb={2}>
              <h2>{meetingDetails.firstName} {meetingDetails.lastName}</h2  >
            </Box>
            <Box mb={2}>
              <FormControl fullWidth>
                <InputLabel>Meet Username</InputLabel>
                <Input type="text" name="meetUsername" defaultValue={data?.meetUserName || ""} />
              </FormControl>
            </Box>
            <Box mb={2}>
              <FormControl fullWidth>
                <InputLabel>Meet Password</InputLabel>
                <Input type="text" name="meetPassword" defaultValue={data?.meetPassword || ""} />
              </FormControl>
            </Box>
            {toastMsg &&
              <Typography mt={2} mb={2}>

                Credentials updated successfully
              </Typography>
            }

            <Button type="submit" variant="contained" color="primary">
              Update credentials
            </Button>
          </form>

          {/* Hello world */}

        </Box> : <>Loading...</>}
    </Modal>
  )
}

export default CredsModal