import React, { useState } from "react";

import {
  Box,
  Card,
  useMediaQuery,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  Button,
  TextField,
} from "@mui/material";
import Header from "components/Header";
import { useGetProductsQuery, usePostBlogMutation } from "state/api";

const BlogForm = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const [addBlog] = usePostBlogMutation();

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleContentChange = (e) => {
    setContent(e.target.value);
  };

  const handleImageUrlChange = (e) => {
    setImageUrl(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    // Add your API call or state update logic here

    addBlog({ title, content, imageUrl });

    // Reset form
    setTitle("");
    setContent("");
    setImageUrl("");

    alert("Blog posted!");
  };

  return (
    <Box p={2}>
      <form onSubmit={handleSubmit}>
        <Box mb={2}>
          <FormControl fullWidth>
            <InputLabel>Title</InputLabel>
            <Input value={title} onChange={handleTitleChange} required />
          </FormControl>
        </Box>
        <Box mb={2}>
          <TextField
            label="Content"
            multiline
            rows={4}
            value={content}
            onChange={handleContentChange}
            required
          />
        </Box>
        <Box mb={2}>
          <FormControl fullWidth>
            <InputLabel>Image URL</InputLabel>
            <Input value={imageUrl} onChange={handleImageUrlChange} required />
          </FormControl>
        </Box>
        <Button type="submit" variant="contained" color="primary">
          Post Blog
        </Button>
      </form>
    </Box>
  );
};

const Blogs = () => {
  const { data, isLoading } = useGetProductsQuery();
  const isNonMobile = useMediaQuery("(min-width: 1000px)");

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Blogs" subtitle="See and manage blogs." />
      {data || !isLoading ? (
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          justifyContent="space-between"
          rowGap="20px"
          columnGap="1.33%"
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
          <BlogForm />
        </Box>
      ) : (
        <>Loading...</>
      )}
    </Box>
  );
};

export default Blogs;
