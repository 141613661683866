import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const TOKEN = JSON.parse(localStorage.getItem("token"));

//check if token is expired
const isTokenExpired = (token) => {
  if (!token) return true;
  const expiry = (JSON.parse(atob(token.split(".")[1]))).exp;
  return Math.floor(new Date().getTime() / 1000) >= expiry;
};

if (!TOKEN) {
  //check if the current path is login, if not redirect to login
  if (window.location.pathname !== "/login")
    window.location.href = "/login";

}
//if token is expired, remove it from local storage
else if (isTokenExpired(TOKEN)) {
  localStorage.removeItem("token");
  window.location.href = "/login";
}

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN}`,
    }
  }),
  reducerPath: "adminApi",
  tagTypes: [
    "User",
    "Products",
    "Customers",
    "Transactions",
    "Geography",
    "Sales",
    "Admins",
    "Performance",
    "Dashboard",
    "MeetingCreds",
    "MeetingCredsInfo",
    "DeletedCourse",
    "Coupons"
  ],
  endpoints: (build) => ({
    getUser: build.query({
      query: (id) => `/users/${id}`,
      providesTags: ["User"],
    }),
    getProducts: build.query({
      query: () => "/courses",
      providesTags: ["Products"],
    }),
    getCustomers: build.query({
      query: () => "/users",
      providesTags: ["Customers"],
    }),
    getTransactions: build.query({
      query: ({ page, pageSize, sort, search }) => ({
        url: "/transactions",
        method: "GET",
        params: { page, pageSize, sort, search },
      }),
      providesTags: ["Transactions"],
    }),
    getGeography: build.query({
      query: () => "client/geography",
      providesTags: ["Geography"],
    }),
    getSales: build.query({
      query: () => "sales/sales",
      providesTags: ["Sales"],
    }),
    getAdmins: build.query({
      query: () => "management/admins",
      providesTags: ["Admins"],
    }),
    getUserPerformance: build.query({
      query: (id) => `management/performance/${id}`,
      providesTags: ["Performance"],
    }),
    getDashboard: build.query({
      query: () => "/dashboard",
      providesTags: ["Dashboard"],
    }),
    postBlog: build.mutation({
      query: (body) => ({
        url: "/blogs",
        method: "POST",
        body,
      }),
      auraa: ["Blogs"],
    }),
    updateCourse: build.mutation({
      query: ({ id, ...body }) => ({
        url: `/courses/${id}`,
        method: "PUT",
        body,
      }),
      auraa: ["Course"],
    }),
    updateMeetingCreds: build.mutation({
      query: ({ id, ...body }) => ({
        url: `updateMeetingCreds/${id}`,
        method: "PATCH",
        body,
      }),
      auraa: ["MeetingCreds"],
    }),
    getMeetingCreds: build.query({
      query: (id) => `updateMeetingCreds/${id}`,
      providesTags: ["MeetingCredsInfo"],
    }),
    deleteCourse: build.mutation({
      query: ({ id }) => ({
        url: `/courses/${id}`,
        method: "DELETE",
      }),
      auraa: ["DeletedCourse"],
    }),
    postCoupon: build.mutation({
      query: (body) => ({
        url: "/coupons",
        method: "POST",
        body,
      }),
      auraa: ["Coupons"],
    }),
    getAllCoupons: build.query({
      query: () => "/coupons",
      providesTags: ["AllCoupons"],
    }),
    postCategory: build.mutation({
      query: (body) => ({
        url: "/categories",
        method: "POST",
        body,
      }),
      auraa: ["Categories"],
    }),
    getAllCategories: build.query({
      query: () => "/categories",
      providesTags: ["AllCategories"],
    }),
    postOffer: build.mutation({
      query: (body) => ({
        url: "/offers",
        method: "POST",
        body,
      }),
      auraa: ["Offers"],
    }),
    getAllOffers: build.query({
      query: () => "/offers",
      providesTags: ["AllOffers"],
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetProductsQuery,
  useGetCustomersQuery,
  useGetTransactionsQuery,
  useGetGeographyQuery,
  useGetSalesQuery,
  useGetAdminsQuery,
  useGetUserPerformanceQuery,
  useGetDashboardQuery,
  usePostBlogMutation,
  useUpdateCourseMutation,
  useUpdateMeetingCredsMutation,
  useGetMeetingCredsQuery,
  useDeleteCourseMutation,
  useGetAllCouponsQuery,
  usePostCouponMutation,
  useGetAllCategoriesQuery,
  usePostCategoryMutation,
  useGetAllOffersQuery,
  usePostOfferMutation
} = api;
